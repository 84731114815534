.react-date-picker-helper {
  .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__triangle {
    left: 48% !important;
  }

  input {
    max-width: 300px;
  }

  @media screen and (max-width: 374px) {
    .react-datepicker {
      max-width: 240px !important;
    }

    .react-datepicker__time-container, .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__navigation--next--with-time {
      right: 10px !important;
    }

    .react-datepicker__time-box {
      width: 100% !important;
    }
  }
}
