.players-page-teams-layout-manager {
  .teams-row {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    padding-top: -1rem;
  }

  .teams-col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media screen and (min-width: 768px) {
        width: 50%
    }

    @media screen and (min-width: 1360px) {
      width: calc(100% / 3);
    }
  }

  .card-body {
    padding: 0 !important;
  }

  .team-card-team-info-span {
    width: 100%;

    @media screen and (min-width: 1100px) {
      width: initial;
    }
  }
}

