@import "./bootstrap-override";

.date-time-picker-selector > div {
  @extend .form-control;

}

.react-datetime-picker__wrapper {
  display: flex !important;
  align-items: center !important;
}
