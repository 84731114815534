.login-background {
  background-image: url("/images/man-swinging.jpg");
  filter: blur(3px);
  background-size: cover;
}

.login-form-card {
  margin: auto;
  max-width: 400px;

  &_mobile-spacer {
    height: 5vh;
  }
}
