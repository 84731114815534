.sidebar {
  width: 270px;
  height: 100vh;
  overflow-y: auto;
}

.sidebar-header-toggle-icon {
  display: flex;
  justify-content: flex-end;
  /* this value is also used in the SideBarManager.tsx file for matching the screen width */
  @media screen and (min-width: 992px) {
    display: none !important;
  }
}

.side-bar-tournament-list {
  ul {
    list-style: none;
  }

  ul li::before {
    content: "\2022";
    color: rgba($gray, 0.5);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-bottom: 0.75rem;
  }

  a {
    display: inline;
  }
}
