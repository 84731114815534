.asset-list-item-button-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  div {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;

    @media screen and (min-width: 440px) {
      width: 50%;
      //display: none !important;
    }

    button {
      width: 100%;
    }
  }
}
