.edit-player-cell {
  &_action-icon {
    pointer-events: none;
    opacity: 0.5;

    &_enabled {
      pointer-events: initial;
      opacity: 1;
    }
  }
}
