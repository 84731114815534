@import "./bootstrap-override";
@import "./sideBar";
@import "./react-bootstrap-table";
@import "./login";
@import "./date-time-picker";
@import "./checkbox";
@import "./react-date-picker";
@import "./chat-page";
@import "./golfCourse";
@import "./players-page";
@import "./players-page-layout.scss";
@import "./tournament-form.scss";
@import "./asset-manager.scss";
@import "./tables.scss";
@import "edit-player-cell";
@import "edit-player-confirm-send-url-modal";
@import "_to-dashboard.scss";

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif;
}

h1 {
  font-weight: 800; // extra bold
}

h2, h3 {
  font-weight: 700; // bold
}

h4, h5, h6 {
  font-weight: 600; // semi-bold
}

label {
  font-weight: bold;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/icons/arrow.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 11px);
  background-position-y: 11px;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.input-group-text {
  background-color: $darkBlue;
  border-color: $darkBlue;
  color: white;
}

textarea {
  height: 100px !important;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.overflow-x-scroll {
  overflow-x: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.max-300 {
  max-width: 300px;
}

//input[type="file"] {
//  display: none;
//}

.input-type-file-custom-button {
  @extend .btn;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
